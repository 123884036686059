

// Libs
import {  ref } from 'vue';

import router from "@/router";

// Components
import PsButton from '@/components/core/buttons/PsButton.vue';
import PsLabel from '@/components/core/label/PsLabel.vue';
import PsLabelCaption from '@/components/core/label/PsLabelCaption.vue';
import PsInputWithLeftIcon from '@/components/core/input/PsInputWithLeftIcon.vue';
import PsIcon from '@/components/core/icons/PsIcon.vue';
import PsDropdown from '@/components/core/dropdown/PsDropdown.vue';
import PsInputWithRightIcon from '@/components/core/input/PsInputWithRightIcon.vue';
import PsLoadingDialog from '@/components/core/dialog/PsLoadingDialog.vue';

// Providers
import { useItemLocationProviderState } from '@/store/modules/itemlocation/ItemLocationProvider';
import { createItemLocationTownshipProviderState } from '@/store/modules/itemLocationTownship/ItemLocationTownshipProvider';
import { useProductProviderState } from '@/store/modules/item/ProductProvider';
import { PsValueProvider } from "@/store/modules/core/PsValueProvider";
import AppInfoParameterHolder from '@/object/holder/AppInfoParameterHolder';
import { usePsAppInfoProviderState } from '@/store/modules/appinfo/AppInfoProvider';

import LocationParameterHolder from '@/object/holder/LocationParameterHolder';
import LocationTownshipParameterHolder from '@/object/holder/LocationTownshipParameterHolder';


export default {
    name : "SearchForLargeScreenView",
    components: {
        PsButton,
        PsLabel,
        PsLabelCaption,
        PsInputWithLeftIcon,
        PsIcon,
        PsDropdown,
        PsInputWithRightIcon,
        PsLoadingDialog,
    },
    props : {
        data : {
            type: String,
            default: '0'
        },
    },
    emits: ['clicklocation','onSubclick'],
    setup( props, context ) {

        // Inject Provider
        const locationKeyword = ref('');
        const sublocationKeyword = ref('');

        const psValueHolder = PsValueProvider.psValueHolder;
        const ps_loading_dialog = ref();

        psValueHolder.updateExpiryTimeForAction(); // only call from dashboard
        const appInfoProvider = usePsAppInfoProviderState();
        const searchKeyword = ref("");
        const locationName = ref(psValueHolder.locationName ?? 'All');
        const locationId = ref(psValueHolder.locationId);
        const sublocationId = ref(psValueHolder.locationId);
        const sublocationName = ref(psValueHolder.subLocationName ?? 'All');

        const itemlocationProvider = useItemLocationProviderState();
        const subLocationProvider = createItemLocationTownshipProviderState();

        const itemProvider = useProductProviderState();


        const loginUserId = psValueHolder.getLoginUserId();

        const paramHolder = new LocationParameterHolder().getDefaultParameterHolder();
        const subparamHolder = new LocationTownshipParameterHolder();


        async function searchClicked() {

            itemProvider.paramHolder.searchTerm = searchKeyword.value;
            itemProvider.paramHolder.itemLocationName = locationName.value.toString();
            itemProvider.paramHolder.itemLocationId = locationId.value;
            itemProvider.paramHolder.itemLocationTownship = psValueHolder.subLocationId;
            itemProvider.paramHolder.itemLocationName = psValueHolder.locationName;
            itemProvider.paramHolder.itemLocationTownshipName = psValueHolder.subLocationName;
            itemProvider.paramHolder.status = '1';

            searchKeyword.value = '';

            const params = itemProvider.paramHolder.getUrlParamsAndQuery();
            params['name'] = 'item-list';
            console.log(params);
            router.push(params);

        }

        function loadLocationList() {

            itemlocationProvider.loadItemLocationList(psValueHolder.getLoginUserId(), paramHolder);
        }

        function loadSubLocation(itemLocationId) {
            subLocationProvider.loadItemLocationTownshipList(itemLocationId);
        }

        async function locationFilterClicked(id,name,lat,lng) {

            locationId.value = id;
            locationName.value = name;

            psValueHolder.replaceLocation(id, name, lat, lng);
            psValueHolder.replaceSubLocation('', 'All');

            sublocationId.value = '';
            sublocationName.value = 'All';
            itemProvider.paramHolder.itemLocationTownship = '';
            itemProvider.paramHolder.itemLocationTownshipName = 'All';
            await itemProvider.updateLocation(loginUserId, locationId.value, locationName.value);
            subLocationProvider.resetItemLocationTownshipList(id);
            context.emit('clicklocation', {id: id,name: name,lat: lat,lng: lng});

        }

        async function subLocationFilterClicked(id,townshipName) {
            sublocationId.value = id;
            sublocationName.value = townshipName;
            psValueHolder.replaceSubLocation(id, townshipName);
            itemProvider.paramHolder.itemLocationTownship = id;
            itemProvider.paramHolder.itemLocationTownshipName = townshipName;
            context.emit('onSubclick', {id: locationId.value,name: locationName.value,subId: id,subName: townshipName});

        }

        function filterKeywordUpate(value) {
            itemlocationProvider.filterKeywordUpate(value,loginUserId, paramHolder);
        }

        function filtersubLocationUpdate(value) {
            subparamHolder.keyword = value;
            subparamHolder.locationId = locationId.value;
            subLocationProvider.filtersubLocationUpdate(loginUserId,subparamHolder);
        }

        const appInfoParameterHolder = new AppInfoParameterHolder();
        appInfoParameterHolder.userId = loginUserId;
        appInfoProvider.loadDeleteHistory(appInfoParameterHolder);

        return {
            PsValueProvider,
            itemlocationProvider,
            subLocationProvider,
            itemProvider,
            searchKeyword,
            searchClicked,
            locationName,
            locationId,
            sublocationId,
            sublocationName,
            loadLocationList,
            locationFilterClicked,
            locationKeyword,
            sublocationKeyword,
            ps_loading_dialog,
            filterKeywordUpate,
            subLocationFilterClicked,
            loadSubLocation,
            appInfoProvider,
            filtersubLocationUpdate
        };
    },
};
