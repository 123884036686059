import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: $props.type,
    disabled: $props.disabled,
    class: _normalizeClass(["items-center theme px-4 py-2 font-medium capitalize tracking-widest active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray transition ease-in-out duration-150", [$props.disabled ? 'bg-primary-500 dark:bg-primaryDark-accent text-secondary-000 dark:text-secondaryDark-grey' : $props.theme, $props.rounded, $props.textSize]])
  }, [
    _renderSlot(_ctx.$slots, "default", { class: "" })
  ], 10, _hoisted_1))
}