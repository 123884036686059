import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-background dark:bg-backgroundDark h-full"
}
const _hoisted_2 = ["dir"]
const _hoisted_3 = { class: "flex-grow" }
const _hoisted_4 = { class: "top-0 fixed topZindex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_progress_bar = _resolveComponent("vue-progress-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ps_nav_tab_bar = _resolveComponent("ps-nav-tab-bar")!
  const _component_ps_nav_bar = _resolveComponent("ps-nav-bar")!
  const _component_footer_view = _resolveComponent("footer-view")!
  const _component_ps_notification_box = _resolveComponent("ps-notification-box")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dataReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            id: "app",
            dir: _ctx.getDir(),
            class: "w-full h-full flex flex-col min-h-screen"
          }, [
            _createVNode(_component_vue_progress_bar),
            _createElementVNode("main", _hoisted_3, [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component, route }) => [
                  _createVNode(_Transition, {
                    name: "slide-fade",
                    mode: "out-in",
                    appear: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock("div", {
                        key: route.fullPath
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                      ]))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ps_nav_tab_bar, { topOfPage: _ctx.atTopOfPage }, null, 8, ["topOfPage"]),
              (_ctx.renderComponent)
                ? (_openBlock(), _createBlock(_component_ps_nav_bar, {
                    key: 0,
                    topOfPage: _ctx.atTopOfPage
                  }, null, 8, ["topOfPage"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("footer", null, [
              _createVNode(_component_footer_view)
            ])
          ], 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ps_notification_box)
  ], 64))
}