
    export default {
        name: "PsButton",
        props: {
            type: {
                type: String,
                default: 'submit',
            },
            theme : {
                type: String,
                default: 'bg-primary-500 dark:bg-primaryDark-accent text-textLight dark:text-textDark'
            },
            rounded : {
                type : String,
                default : "rounded-x sm:rounded-xl lg:rounded-2xl" 
            },
            disabled: {
                type: Boolean,
                default: false
            },
            textSize :{
                type: String,
                default: 'lg:text-sm sm:text-xs text-xxs'
            }
        }
    }
