import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ps_label_title = _resolveComponent("ps-label-title")!
  const _component_ps_label = _resolveComponent("ps-label")!
  const _component_ps_modal = _resolveComponent("ps-modal")!

  return (_openBlock(), _createBlock(_component_ps_modal, {
    ref: "psmodal",
    maxWidth: "350px",
    isClickOut: false,
    class: "z-50"
  }, {
    title: _withCtx(() => [
      _createVNode(_component_ps_label_title, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("ps_loading_dialog__please_wait")), 1)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_ps_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.message), 1)
        ]),
        _: 1
      })
    ]),
    footer: _withCtx(() => []),
    _: 1
  }, 512))
}