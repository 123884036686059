
import { PsValueProvider } from '@/store/modules/core/PsValueProvider';
export default {
    name: "PsInputWithRightIcon",
    props: {
        "value" : {
            type: String,
            default: ""
        },
        "type" : {
            type: String,
            default: "text"
        },
        "theme" : {
            type: String,
            default: " py-2 ps-4 form-input bg-primary-000 dark:bg-primaryDark-black text-secondary-500 dark:text-secondaryDark-white "
        }, 
        "placeholder": String
    },
    setup(_, {emit}) {
        const psValueHolder = PsValueProvider.psValueHolder;
        function handleInput(v) {                
            emit('update:value', v)
        }

        return {
            handleInput,
            psValueHolder
        }
    }
}
