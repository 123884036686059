import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["type", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: _normalizeClass(["w-full shadow-sm pe-10 ps-7 text-sm", $props.theme]),
      type: $props.type,
      value: $props.value,
      onInput: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleInput($event.target.value))),
      placeholder: $props.placeholder
    }, null, 42, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute inset-y-0 flex items-center ms-2", $setup.psValueHolder.languageCode == 'ar' ? 'right-0' : 'left-0'])
    }, [
      _renderSlot(_ctx.$slots, "icon", { class: "" })
    ], 2)
  ]))
}