
import { PsValueProvider } from '@/store/modules/core/PsValueProvider';
export default {        
    name: "PsInputWithLeftIcon",
    props: {
        "value" : String,
        "type" : {
            type: String,
            default: "text"
        },
        "height" : {
            type: String,
            default: ""
        },
        "theme" : {
            type: String,
            default: "ring-primary-200 dark:ring-backgrounddark-400 focus:outline-none focus:ring focus:border-primary-200 py-2 ps-4 form-input border border-green-500 bg-primary-000 border-background-300 dark:bg-primaryDark-black dark:border-backgrounddark-500 text-secondary-500 dark:text-secondaryDark-white "
        }, 
        "placeholder": String
    },
    setup(_, {emit}) {
        const psValueHolder = PsValueProvider.psValueHolder;
        function handleInput(v : any) {                
            emit('update:value', v);
        }

        return { handleInput,psValueHolder };
    }
}
