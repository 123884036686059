export default class PsConfig {

    static versionNo : String = '1.3';

    ///
    /// API Key to connection with Main Backend
    /// This key must equal with key from backend
    ///
    static apiKey: String = "teampsisthebest";

    ///
    /// Live Mode
    /// ------------
    /// If you want to upload to server, please enable below and comment Development below
    ///
    // static fixedDomain: String = "http://localhost/sellitout/admin";
    // static domain: String = "http://localhost:3000";

    static fixedDomain: String = "https://sellitout.ca/backend";
    static domain: String = "https://sellitout.ca/backend";
    ///
    /// Development Mode
    /// -----------------
    /// Before upload to server, please comment this 2 lines.
    ///
    // static fixedDomain: String = "https://products.panacea-soft.co/flutter-buysell-demo";
    // static domain: String = "http://localhost:3000";

    ///
    /// Firebase Config
    ///
    static firebaseConfig = {
        apiKey: "AIzaSyBHJ-l9PzCkmq25kBQeiHjZNskUljhsBXg",
        authDomain: "sellitout-d304a.firebaseapp.com",
        databaseURL: "https://sellitout-d304a-default-rtdb.firebaseio.com",
        projectId: "sellitout-d304a",
        storageBucket: "sellitout-d304a.appspot.com",
        messagingSenderId: "691710209037",
        appId: "1:691710209037:web:5543946444ef47e7dc7944",
        measurementId: "G-3J7L3TWVHC"
    };

    // static firebaseConfig = {
    //     apiKey: "AIzaSyCtBHbqTWRgh9O8veCOJNnCMG56lXTdGJw",
    //     authDomain: "flutter-buy-and-sell.firebaseapp.com",
    //     databaseURL: "https://flutter-buy-and-sell.firebaseio.com",
    //     projectId: "flutter-buy-and-sell",
    //     storageBucket: "flutter-buy-and-sell.appspot.com",
    //     messagingSenderId: "505384053995",
    //     appId: "1:505384053995:web:f22081b8b835af474ae2ae",
    //     measurementId: "G-2CNXCDNZ2Y"
    // };

    ///
    /// Base URL * Don't update it *
    ///
    static base: String = PsConfig.domain;
    static baseUrl: String = PsConfig.domain + '/index.php';
    static fixedPath : String = PsConfig.fixedDomain;

    ///
    /// Debug Mode
    ///
    static isDebugMode : Boolean = true;

    /// Is Demo
    ///
    static is_demo : Boolean = true;

    static item_upload_ui1 : Boolean = true;

    static item_upload_ui2 : Boolean = false;
}
